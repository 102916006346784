import React, { Fragment, useEffect, useState } from "react";
import {
  PageHeader,
  Button,
  Card,
  Table,
  Space,
  Input,
  Form,
  Select,
  Modal,
  message,
  Popconfirm,
  Descriptions,
  Typography,
  Menu,
  Dropdown,
  Row,
  Col,
} from "antd";
import Role from "../../models/role/role";
import {
  SearchOutlined,
  PlusOutlined,
  LockOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  isPermissionPresent,
  stringSorting,
  validatePasswordField,
} from "../../utilities/generalUtility";
import K from "../../utilities/constants";
import User from "../../models/user/user";
import { useDispatch, useSelector } from "react-redux";
import BaseModel from "../../models/baseModel/baseModel";
var md5 = require("md5");

const { Option } = Select;

export default function ManageUsers() {
  let searchInput = "";
  const [form] = Form.useForm();
  const [updateRoleForm] = Form.useForm();
  const [changeForm] = Form.useForm();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [user, setUser] = useState(null);
  const [isRoleModalVisible, setIsRoleModalVisible] = useState(false);
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const rolesList = useSelector((state) => {
    return BaseModel.getSelector(Role)(state);
  });
  const userList = useSelector((state) => {
    return BaseModel.getSelector(User)(state);
  });

  useEffect(() => {
    getAllUsers();
    setIsLoading(false);
  }, []);

  const getAllUsers = async () => {
    try {
      const users = await dispatch(User.getAll());
    } catch (error) {
      console.error(error);
    }
  };

  const getRoles = async () => {
    try {
      await dispatch(Role.getRoles());
    } catch (error) {
      console.error(error);
    }
  };

  const deleteUser = async (id) => {
    try {
      await dispatch(User.deleteUser(id));
      message.success("User Deleted!");
    } catch (error) {
      console.error(error);
    }
  };
  const onSubmit = async (values) => {
    setIsLoading(true);
    try {
      const res = await User.sendInvite(values);
      message.success(`An email has been sent to ${values.email}`);
      setIsInviteModalVisible(false);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };
  const handleOk = () => {
    form.submit();
  };
  const handleChange = () => {
    changeForm.submit();
  };
  const updateRole = () => {
    updateRoleForm.submit();
  };
  const showInviteModal = () => {
    getRoles();
    form.resetFields();
    setIsInviteModalVisible(true);
  };
  const handleUpdate = async (values) => {
    setIsLoading(true);
    try {
      const res = await dispatch(User.updateUserRole(user.id, values.role));
      setIsRoleModalVisible(false);
      message.success("User Role Updated!");
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };
  const changePassword = async (values) => {
    setIsLoading(true);
    try {
      const res = await User.changePassword(user.id, md5(values.password));
      message.success(res.message);
      setIsPasswordModalVisible(false);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const handleCancel = () => {
    setIsInviteModalVisible(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder="Search Name"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Clear
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: filtered ? "#1890ff" : undefined, fontSize: 16 }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    /* render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ), */
  });
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => stringSorting(a, b, "name"),
      render: (text, record) => {
        if (record.email === User.getEmail()) text = text.concat(" (You)");
        return searchedColumn === "name" ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        );
      },
      ...getColumnSearchProps("name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => stringSorting(a, b, "email"),
      render: (text) => {
        return searchedColumn === "email" ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        );
      },
      ...getColumnSearchProps("email"),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      sorter: (a, b) => stringSorting(a, b, "role"),
      render: (text) =>
        searchedColumn === "role" ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
      ...getColumnSearchProps("role"),
    },
    isPermissionPresent([K.Permissions.ManipulateUser])
      ? {
          title: "Action",
          dataIndex: "action",
          key: "action",
          // width: "25%",
          align: "center",

          render: (value, record) => {
            const menu = (
              <Menu>
                {!isPermissionPresent([K.Permissions.SuperAdmin]) && (
                  <Menu.Item
                    onClick={() => {
                      getRoles();
                      setUser(record);
                      setIsRoleModalVisible(true);
                      updateRoleForm.setFieldsValue({ role: record.roleId });
                    }}
                    key="1"
                  >
                    Edit Role
                  </Menu.Item>
                )}
                <Menu.Item
                  onClick={() => {
                    changeForm.resetFields();
                    setUser(record);
                    setIsPasswordModalVisible(true);
                  }}
                  key="2"
                >
                  Change Password
                </Menu.Item>
                <Menu.Item key="3">
                  <Popconfirm
                    placement="bottom"
                    title="Are you sure to delete this user?"
                    onConfirm={() => {
                      deleteUser(record.id);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <div
                      onClick={(e) => e.stopPropagation()}
                      style={{ height: "100%", width: "100%" }}
                    >
                      Delete
                    </div>
                  </Popconfirm>
                </Menu.Item>
              </Menu>
            );
            if (record.email !== User.getEmail())
              return (
                <Dropdown trigger={["click", "hover"]} overlay={menu}>
                  <MoreOutlined />
                </Dropdown>
              );
          },
        }
      : null,
  ];

  return (
    <Fragment>
      <PageHeader
        title="Manage Users"
        className="bg-white d-flex justify-content-between align-items-center"
      >
        {isPermissionPresent([K.Permissions.InviteUser]) && (
          <Button size="large" key="1" type="primary" onClick={showInviteModal}>
            <PlusOutlined /> Invite
          </Button>
        )}
      </PageHeader>
      <div className="cercadian-content-area p-3">
        <Card className="card-box bg-white pt-0">
          <Table
            className="cercadian-table"
            dataSource={userList}
            columns={columns.filter(Boolean)}
          />
        </Card>
      </div>
      <Modal
        title="Invite User"
        visible={isInviteModalVisible}
        okText="Invite"
        onOk={handleOk}
        okButtonProps={{ loading: isLoading }}
        onCancel={handleCancel}
      >
        <Form
          layout="vertical"
          name="invite-form"
          form={form}
          onFinish={onSubmit}
        >
          <Row gutter={[16, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  { required: true, message: "Please enter first name !" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  { required: true, message: "Please enter last name !" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please enter valid email!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          {!isPermissionPresent([K.Permissions.SuperAdmin]) && (
            <Form.Item
              name="roleName"
              label="Role"
              rules={[{ required: true, message: "Please select role!" }]}
            >
              <Select>
                {rolesList.map((e, i) => {
                  return (
                    <Option key={i} value={e.name}>
                      {e.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
        </Form>
      </Modal>
      <Modal
        title="Change Password"
        visible={isPasswordModalVisible}
        okText="Change"
        onOk={handleChange}
        okButtonProps={{ loading: isLoading }}
        onCancel={() => {
          setIsPasswordModalVisible(false);
        }}
      >
        <Form
          name="change-password-form"
          form={changeForm}
          onFinish={changePassword}
        >
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                min: 6,
                message: "Password must be atleast 6 characters long!",
              },
            ]}
          >
            <Input.Password
              prefix={
                <LockOutlined className="site-form-item-icon text-primary" />
              }
              placeholder="Password"
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            rules={[
              () => ({
                validator(rule, value) {
                  return validatePasswordField(
                    value,
                    changeForm.getFieldValue("password")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              prefix={
                <LockOutlined className="site-form-item-icon text-primary" />
              }
              placeholder="Confirm Password"
              size="large"
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        okText="Save"
        className="cr-modal"
        title="Assign Roles"
        width={404}
        visible={isRoleModalVisible}
        onOk={updateRole}
        okButtonProps={{ loading: isLoading }}
        onCancel={() => {
          setIsRoleModalVisible(false);
        }}
      >
        <Descriptions className="user-mail" title="User Info">
          <Descriptions.Item label="Name" span={3}>
            {user?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Email">{user?.email}</Descriptions.Item>
        </Descriptions>
        <Typography.Title level={5} className="heading-2">
          Select Role
        </Typography.Title>
        <Form form={updateRoleForm} onFinish={handleUpdate}>
          <Form.Item name="role">
            <Select size="large" style={{ width: "100%" }}>
              {rolesList.map((role, i) => (
                <Option key={i} value={role.id}>
                  {role.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
}
