import NetworkCall from "../../network/networkCall";
import Request from "../../network/request";
import {
  deleteModel,
  upsertModel,
  upsertModels,
} from "../baseModel/baseActions";
import BaseModel from "../baseModel/baseModel";
import baseReducer from "../baseModel/baseReducer";

export default class Role extends BaseModel {
  static getRoles() {
    return async (dispatch) => {
      const list = await NetworkCall.fetch(Request.getRoles());
      dispatch(upsertModels(Role, list));
      return list;
    };
  }

  static deleteRole(roleId) {
    return async (dispatch) => {
      const res = await NetworkCall.fetch(Request.deleteRole(roleId));
      dispatch(deleteModel(Role, roleId));
    };
  }

  static async getRoleById(id) {
    const res = await NetworkCall.fetch(Request.getRoleById(id));
    return res;
  }

  static createRole(name, permissions) {
    return async (dispatch) => {
      const role = await NetworkCall.fetch(
        Request.createRole(name, permissions),
        false
      );
      dispatch(upsertModel(Role, role));
      return role;
    };
  }

  static editRole(id, name, permissions) {
    return async (dispatch) => {
      const role = await NetworkCall.fetch(
        Request.editRole(id, name, permissions),
        false
      );
      dispatch(upsertModel(Role, role));
      return role;
    };
  }
  // Reducer
  static reducer(action, Role, session) {
    baseReducer(action, Role, session);
  }
}

Role.modelName = "Role";
