import { attr } from "redux-orm";
import NetworkCall from "../../network/networkCall";
import Request from "../../network/request";
import K from "../../utilities/constants";
import {
  deleteModel,
  upsertModel,
  upsertModels,
} from "../baseModel/baseActions";
import BaseModel from "../baseModel/baseModel";
import baseReducer from "../baseModel/baseReducer";
import User from "../user/user";

export default class SavedReport extends BaseModel {
  static getReports() {
    return async (dispatch) => {
      const list = await NetworkCall.fetch(Request.getSavedReports());
      const isSuperAdmin = User.getAllPermissions().includes(
        K.Permissions.SuperAdmin
      );
      dispatch(
        upsertModels(
          SavedReport,
          list.map((item) => ({
            ...item,
            key: item.id,
            savedBy: isSuperAdmin
              ? item.company?.name ?? K.NullValue
              : item.user?.firstName.concat(" ", item.user.lastName),
          }))
        )
      );
      return list;
    };
  }
  static async getReportById(id) {
    const res = await NetworkCall.fetch(Request.getReportById(id));
    return res;
  }

  static deleteReport(reportId) {
    return async (dispatch) => {
      const res = await NetworkCall.fetch(Request.deleteSavedReport(reportId));
      dispatch(deleteModel(SavedReport, reportId));
    };
  }

  static renameReport(reportId, name) {
    return async (dispatch) => {
      const res = await NetworkCall.fetch(
        Request.renameSavedReport(reportId, name),
        false
      );
      dispatch(upsertModel(SavedReport, res));
      return res;
    };
  }

  static createReport(name, data) {
    return async (dispatch) => {
      const list = await NetworkCall.fetch(
        Request.createSavedReport(name, data)
      );
      dispatch(upsertModel(SavedReport, { id: list.id, name: list.name }));
      return list;
    };
  }
  // Reducer
  static reducer(action, SavedReport, session) {
    baseReducer(action, SavedReport, session);
  }
}

SavedReport.modelName = "SavedReport";

SavedReport.fields = {
  // Attributes
  id: attr(),
  name: attr(),
};
