import { DualAxes } from "@ant-design/charts";
import React, { useEffect, useState } from "react";
import styles from './barChart.module.scss';

export default function BarChart({ data }) {
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (data) {
      if (data.sequences.sequence.length > 1) {
        const tempData = Object.keys(data.sequences.sequence).map((key) => {
          return data.sequences.sequence[key];
        });
        setDataSource(tempData);
      } else {
        const tempData = Object.keys(data.sequences).map((key) => {
          return data.sequences[key];
        });
        setDataSource(tempData);
      }
    }
  }, [data]);
  
  let array = [];
  for (let i = 0; i <= 100; i++) {
    let riskFactor = 1;

    if (i >= 43) {
      let fs = i + 1;
      
      // Provided by the Circadian team for calculating the risk factor line graph
      riskFactor = 0.0000048724 * (fs**4) - 0.0004232155 * (fs**3) + 0.0076758729 * (fs**2) + 0.0798546479 * fs + 0.364389914; 
    }

    array.push({ Frequency: 0, FatigueScore: i, RiskFactor: (riskFactor <= 80 ? riskFactor : null) });
  }

  dataSource.forEach((e) => {
    if (e.FatigueScore !== undefined) {
      let count = Math.floor(e.FatigueScore);
      array[count].Frequency++;
    }    
  });
  
  const config = {
    data: [array, array],
    xField: "FatigueScore",
    yField: ["Frequency", "RiskFactor"],
    legend: false,
    tooltip: {
      showTitle: false,
      fields: ["FatigueScore", "Frequency"],
      customItems: (originalItems) => {
        /*
        The tooltips were displaying info relating to the risk factor line graph,
        which didn't have any information relevant to the user. The tooltip items 
        were arrays with 4 elements each. Remove the last 2 elements to only
        display information on the fatigue score and its frequency.
        */
        return originalItems.slice(0, 2);
      }
    },
    
    yAxis: {
      title: {text: "Frequency", visible: true},
      RiskFactor: {
        tickCount: 10
      }
    },
    padding: 40,
    height: 400,
    width: 700,
    geometryOptions: [
      {
        geometry: 'column',
        color: (item) => {
          if (item.FatigueScore >= 0 && item.FatigueScore < 31) {
            return "green";
          } else if (item.FatigueScore >= 31 && item.FatigueScore <= 55) {
            return "yellow";
          }
    
          return "red";
        },
      },
      {
        geometry: 'line',
        lineStyle: {
          lineWidth: 2,
        },
        smooth: true,
        color: "rgb(80, 80, 80)"
      },
    ],
  };
  return (
    <div style={{height: "400px"}}>
      <div className={styles.leftLabel}>Frequency</div>
      <div className={styles.rightLabel}>Risk Factor</div>
      
      <DualAxes {...config} />
      <div className={styles.xAxisLabel}>Fatigue Score</div>
    </div>
  );
}
