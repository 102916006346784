import { Card, PageHeader } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ExportCSV from "../../common/components/exportCSV/exportCSV";
import SavedReport from "../../models/savedReport/savedReport";
import SearchTable from "../search/searchTable";
import ReportChart from "../reportChart/reportChart";

export default function ViewSavedReport() {
  const { id } = useParams();
  const history = useHistory();
  const [reportName, setReportName] = useState("");
  const [reportData, setReportData] = useState(null);
  const [CSVData, setCSVData] = useState([]);
  const [CSVColumns, setCSVColumns] = useState([]);

  const getReportById = async () => {
    try {
      const report = await SavedReport.getReportById(id);
      setReportName(report.name);
      setReportData(JSON.parse(report.data));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getReportById();
  }, []);
  
  return (
    <Fragment>
      <PageHeader
        onBack={() => {
          history.goBack();
        }}
        title={reportName ?? "Report"}
        className="bg-white d-flex justify-content-between align-items-center"
      >
        <ExportCSV
          fileName="generated_report"
          tableData={CSVData}
          columns={CSVColumns}
        />
      </PageHeader>
      <div className="cercadian-content-area p-3">
        <Card className="card-box bg-white pt-0">

          <ReportChart reportData={reportData} />
          <SearchTable
            data={reportData}
            setCSVData={setCSVData}
            setCSVColumns={setCSVColumns}
          />
        </Card>
      </div>
    </Fragment>
  );
}
