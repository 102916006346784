import React, { Fragment, useEffect, useState } from "react";
import {
  PageHeader,
  Button,
  Card,
  Table,
  Space,
  Input,
  Layout,
  Modal,
  Popconfirm,
  message,
} from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import Role from "../../models/role/role";
import BaseModel from "../../models/baseModel/baseModel";
import { stringSorting } from "../../utilities/generalUtility";
import Permission from "../../models/permission/permission";
import ViewRole from "./viewRole";
import CreateRole from "./createRole";

const { Content } = Layout;

export default function Roles() {
  let searchInput = "";
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [roleName, setRoleName] = useState(undefined);
  const [roleData, setRoleData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const rolesList = useSelector((state) => {
    return BaseModel.getSelector(Role)(state);
  });
  const permissionsList = useSelector((state) => {
    return BaseModel.getSelector(Permission)(state);
  });
  const deleteRole = async (id) => {
    try {
      await dispatch(Role.deleteRole(id));
      message.success("Role Deleted!");
    } catch (error) {
      console.error(error);
    }
  };

  const createRole = async () => {
    setIsLoading(true);

    try {
      await dispatch(Role.createRole(roleName, targetKeys));
      message.success("Role Created Successfully");
      setIsModalVisible(false);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const editRole = async () => {
    setIsLoading(true);
    console.log(roleData.id, roleName, targetKeys);
    try {
      await dispatch(Role.editRole(roleData.id, roleName, targetKeys));
      message.success("Role Updated");
      setIsModalVisible(false);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const createRoleModal = () => {
    setIsEditMode(false);
    setRoleData(null);
    setRoleName(undefined);
    setTargetKeys([]);
    setIsModalVisible(true);
  };

  const editRoleModal = (record) => {
    setRoleData(record);
    setIsEditMode(true);
    setRoleName(record.name);
    setTargetKeys(record.permissions.map((item) => item.id));
    setIsModalVisible(true);
  };

  const userRoleModal = () => {
    setIsUserModalVisible(true);
  };

  const userRolehandleOk = () => {
    setIsUserModalVisible(false);
  };

  const userRolehandleCancel = () => {
    setIsUserModalVisible(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder="Search Name"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Clear
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: filtered ? "#1890ff" : undefined, fontSize: 16 }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => stringSorting(a, b, "name"),
      sortDirections: ["ascend", "descend"],
      ...getColumnSearchProps("name"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "20%",
      render: (value, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => {
              setRoleData(record);
              userRoleModal();
            }}
          >
            View
          </Button>
          |
          <Button
            type="link"
            onClick={() => {
              editRoleModal(record);
            }}
          >
            Edit
          </Button>
          |
          <Popconfirm
            title="Are you sure to delete this role?"
            onConfirm={() => {
              deleteRole(record.id);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const getRoles = async () => {
    try {
      await dispatch(Role.getRoles());
    } catch (error) {
      console.error(error);
    }
  };
  const getPermissions = async () => {
    try {
      await dispatch(Permission.getAll());
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getRoles();
    getPermissions();
    setIsLoading(false);
  }, []);

  return (
    <Fragment>
      <PageHeader
        title="Roles"
        className="bg-white d-flex justify-content-between align-items-center"
      >
        <Button size="large" key="1" type="primary" onClick={createRoleModal}>
          <PlusOutlined /> Create Role
        </Button>
      </PageHeader>
      <Content className="cercadian-content-area p-3">
        <Card className="card-box bg-white pt-0">
          <Table
            className="cercadian-table"
            dataSource={rolesList.filter((role) => role.name !== "ClientAdmin")}
            columns={columns}
            pagination={true}
          />
        </Card>
      </Content>

      <Modal
        okText="Save"
        className="cr-modal"
        width={658}
        title={isEditMode ? "Edit Role" : "Create Role"}
        visible={isModalVisible}
        onOk={isEditMode ? editRole : createRole}
        okButtonProps={{ loading: isLoading }}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        // okButtonProps={{
        //   disabled: roleName && targetKeys.length !== 0 ? false : true,
        // }}
      >
        <CreateRole
          roleName={roleName}
          setRoleName={setRoleName}
          permissionsList={permissionsList}
          targetKeys={targetKeys}
          setTargetKeys={setTargetKeys}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
        />
      </Modal>
      <Modal
        footer={null}
        className="cr-modal"
        title="User Role"
        visible={isUserModalVisible}
        onOk={userRolehandleOk}
        onCancel={userRolehandleCancel}
      >
        <ViewRole role={roleData} />
      </Modal>
    </Fragment>
  );
}
