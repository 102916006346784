import { Bar } from "@ant-design/charts";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import {
  completeDateFormat,
  completeTimeFormat,
} from "../../utilities/dateUtility";

function GanttChart({ data }) {
  const [dataSource, setDataSource] = useState([]);
  const [sequenceStartDate, setsequenceStartDate] = useState("");
  const [sequenceEndDate, setsequenceEndDate] = useState("");
  const [tasksStartDate, settasksStartDate] = useState("");
  const [tasksEndDate, settasksEndDate] = useState("");
  let startDate = "";
  let endDate = "";

  let tasks = [];
  let ARRAY = [];
  let fakeTasksArray = [];

  useEffect(() => {
    if (data) {
      const tempData = Object.keys(data.tasks.task).map((key) => ({
        ...data.tasks.task.map((key) => ({
          startDate: completeDateFormat(key.utc_start),
          endDate: completeDateFormat(key.utc_end),
          startTime: completeTimeFormat(key.utc_start),
          endTime: completeTimeFormat(key.utc_end),
          AverageAlertness: key.AverageAlertness,
        })),
      }));
      setDataSource(...tempData);
      setsequenceStartDate(completeDateFormat(data.utc_start));
      setsequenceEndDate(completeDateFormat(data.utc_end));
      settasksStartDate(completeDateFormat(data.tasks.task[0].utc_start));
      settasksEndDate(
        completeDateFormat(
          data.tasks.task[data.tasks.task.length - 1].utc_end
        )
      );
    }
  }, [data]);

  if (dataSource) {
    const arrayOfObjects = Object.keys(dataSource).map((key) => {
      return dataSource[key];
    });

    if (arrayOfObjects) {
      arrayOfObjects.forEach((e, i) => {
        if (e.startDate === e.endDate) {
          tasks.push({
            type: `${e.startDate}`,
            values: [
              parseFloat(
                +e.startTime.split(":")[0] + +e.startTime.split(":")[1] / 60
              ),
              parseFloat(
                +e.endTime.split(":")[0] + +e.endTime.split(":")[1] / 60
              ),
            ],
            Time: `${e.startTime} - ${e.endTime}`,
            startTime: e.startTime,
            endTime: e.endTime,
            AverageAlertness: e.AverageAlertness,
          });
        } else {
          tasks.push({
            type: `${e.startDate}`,
            values: [
              parseFloat(
                +e.startTime.split(":")[0] + +e.startTime.split(":")[1] / 60
              ),
              24,
            ],
            Time: `${e.startTime} - ${e.endTime}`,

            startTime: e.startTime,
            endTime: "24:00",
            AverageAlertness: e.AverageAlertness,
          });

          tasks.push({
            type: `${e.endDate}`,
            values: [
              0,
              parseFloat(
                +e.endTime.split(":")[0] + +e.endTime.split(":")[1] / 60
              ),
            ],
            Time: `${e.startTime} - ${e.endTime}`,

            startTime: "00:00",
            endTime: e.endTime,
            AverageAlertness: e.AverageAlertness,
          });
        }
      });
    }
  }
  if (sequenceStartDate && sequenceEndDate && tasksStartDate && tasksEndDate) {
    startDate =
      new Date(sequenceStartDate) < new Date(tasksStartDate)
        ? sequenceStartDate
        : tasksStartDate;
    endDate =
      new Date(sequenceEndDate) > new Date(tasksEndDate)
        ? sequenceEndDate
        : tasksEndDate;
  }
  while (new Date(startDate) <= new Date(endDate)) {
    fakeTasksArray.push({
      type: `${startDate}`,
    });

    startDate = moment(startDate).add(1, "days").format("MM/DD/YYYY");
  }

  ARRAY = [
    ...tasks,
    ...fakeTasksArray.filter(
      (el) => !tasks.find((task) => el.type === task.type)
    ),
  ];

  ARRAY.sort(function (a, b) {
    return new Date(b.type) - new Date(a.type);
  });

  const config = {
    data: ARRAY.reverse(),
    // data: tasks,
    xField: "values",
    yField: "type",
    isRange: true,
    // isGroup: true,
    legend: false,
    tooltip: {
      showTitle: true,
      fields: ["Time", "AverageAlertness"],
      shared: false,
    },
   
    xAxis: {
      minLimit: 0,
      maxLimit: 24,
      tickCount: 25,
      title: {
        text: "Time",
      },
    },
    yAxis: {
      title: {
        text: "Date",
      },
    },

    seriesField: "AverageAlertness",
    color: ({ AverageAlertness }) => {
      if (AverageAlertness < 25) {
        return "red";
      } else if (AverageAlertness >= 25 && AverageAlertness <= 33) {
        return "orange";
      } else if (AverageAlertness > 33 && AverageAlertness <= 71) {
        return "yellow";
      } else if (AverageAlertness > 71 && AverageAlertness <= 100) {
        return "green";
      }
    },
  };

  return <Bar {...config} />;
}
export default memo(GanttChart);
