import React, { Fragment } from "react";
import { Form, Input, Button, Card, Divider, Image } from "antd";
import { UserOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import User from "../../models/user/user";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, Link } from "react-router-dom";
import Circadianlogo from "../../assets/images/circadian-logo.png";

import { message } from "antd";
import { setFieldErrorsFromServer } from "../../utilities/generalUtility";

export default function ForgotPassword() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const onFinish = async (values) => {
    try {
      await User.forgotPassword(values.email);
      message.success(`An email has been sent to ${values.email}`);
    } catch (error) {
      setFieldErrorsFromServer(error, form, values);
    }
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <Fragment>
      <div className="login-container">
        <Link to="/">
          <div className="lc-logo">
            <Image preview={false} src={Circadianlogo} />
          </div>
        </Link>
        <Card bordered={false} className="login-card">
          <h4>FORGOT PASSWORD</h4>
          <Form
            form={form}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email!",
                },
              ]}
            >
              <Input
                prefix={
                  <UserOutlined
                    style={{ marginRight: 15 }}
                    className="site-form-item-icon text-primary"
                  />
                }
                placeholder="Email"
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Button
                className="login-btn"
                block
                size="large"
                type="primary"
                htmlType="submit"
              >
                Reset
              </Button>
            </Form.Item>
            <Divider plain>OR</Divider>
            <Form.Item className="mb-0 text-center">
              <Link to="/login">
                <ArrowLeftOutlined /> Back to Login
              </Link>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </Fragment>
  );
}
