import React, { Fragment, useEffect } from "react";
import { Form, Input, Button, Checkbox, Card, message, Image } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import User from "../../models/user/user";
import { useDispatch } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import Circadianlogo from "../../assets/images/circadian-logo.png";
import Footer from "../../layout/footer";
import {
  deleteQueryParam,
  redirectToUrl,
  setFieldErrorsFromServer,
} from "../../utilities/generalUtility";
import qs from "qs";
var md5 = require("md5");

export default function Login() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [form] = Form.useForm();
  const paramJson = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if (paramJson.err) {
      message.error(paramJson.err);
      deleteQueryParam("err");
    }
  }, []);

  const onFinish = async (values) => {
    try {
      let user = await dispatch(
        User.loginCall(values.email, md5(values.password), values.remember)
      );
      redirectToUrl("", "/");
    } catch (error) {
      setFieldErrorsFromServer(error, form, values);
    }
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <Fragment>
      <div className="login-container">
        <Link to="/">
          <div className="lc-logo">
            <Image preview={false} src={Circadianlogo} />
          </div>
        </Link>
        <Card bordered={false} className="login-card">
          <h4>LOGIN</h4>
          <Form
            form={form}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email!",
                },
              ]}
            >
              <Input
                type="email"
                prefix={
                  <UserOutlined className="site-form-item-icon text-primary" />
                }
                placeholder="Email"
                size="large"
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                prefix={
                  <LockOutlined className="site-form-item-icon text-primary" />
                }
                placeholder="Password"
                size="large"
              />
            </Form.Item>
            <Form.Item style={{marginBottom: "0"}}>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <Link
                to="/forgot-password"
                className="float-right forgot-password"
                href=""
              >
                Forgot your password?
              </Link>
            </Form.Item>
            <Form.Item style={{textAlign: "center", marginBottom: "5px"}}>
              I accept the <Link to={{ pathname: process.env.REACT_APP_TERMS_OF_USE_URL }} target="_blank">Terms of Use</Link>
            </Form.Item>
            <Form.Item className="mb-0">
              <Button
                className="login-btn"
                block
                size="large"
                type="primary"
                htmlType="submit"
              >
                Sign In
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>

      <div className="login-page-marketing-container">
        <div className="content-background"></div>
        <div className="login-page-marketing-content">
          <p className="content center"><h3>CAS Fatigue Risk Model</h3></p>

          <p className="content center">
            <span className="sub-heading">Fatigue is a Major Source of Human Impairment:</span><br />
            <span className="sub-heading"><i>Are you Measuring & Tracking Your Fatigue Risk?</i></span>

            <ul>
              <li>CAS is the leading scientifically-validated workforce fatigue risk model</li>
              <li>20+ year track record of reducing errors, incidents & injuries in real-world operations</li>
              <li>Upload work schedules & get instant fatigue risk score for every employee</li>
            </ul>
          </p>

          <div className="button-links-container">
            <div className="button-links-row">
              <a className="register-link" target="_blank" href={process.env.REACT_APP_REGISTER_URL}>Create Account</a>
            </div>

            <div className="button-links-row">
              <a className="button-link square" href={process.env.REACT_APP_PRICING_URL} target="_blank">Pricing</a>
              <a className="button-link square" href={process.env.REACT_APP_VIDEO_DEMO_URL} target="_blank">Video Demo</a>
              <a className="button-link square" href={process.env.REACT_APP_BENEFITS_URL} target="_blank">Benefits of CAS &trade;</a>
            </div>

            <div className="button-links-row">
              <a className="button-link wide" target="_blank" href={process.env.REACT_APP_FAQ_URL}>FAQ</a>
            </div>
          </div>
          <br />

          <div className="quote-container">
            <p className="login-page-quote center"><i>"If you can't measure it, you can't improve it."</i></p>
            <p className="login-page-quote-name">- Peter Drucker</p>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}
