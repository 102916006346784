import React, { Fragment, useState } from "react";
import EditProfile from "./editProfile";
import ViewProfile from "./viewProfile";

export default function Profile() {
  const [visible, setVisible] = useState(true);

  const handleEdit = () => {
    setVisible(!visible);
  };
  return (
    <Fragment>
      {visible ? (
        <ViewProfile handleEdit={handleEdit} />
      ) : (
        <EditProfile handleEdit={handleEdit} />
      )}
    </Fragment>
  );
}
