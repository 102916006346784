import K from "../utilities/constants";
import Clients from "../features/clients/clients";
import ForgotPassword from "../features/forgotPassword/forgotPassword";
import Login from "../features/login/login";
import ManageUsers from "../features/manageUsers/manageUsers";
import NotFound from "../features/notFound/notFound";
import Permissions from "../features/permissions/permissions";
import Profile from "../features/profile/profile";
import Register from "../features/register/register";
import Roles from "../features/Roles/roles";
import SavedReports from "../features/savedReports/savedReports";
import ViewSavedReport from "../features/savedReports/viewSavedReport";
import Search from "../features/search/search";
import SetPassword from "../features/setPassword/setPassword";
import Unauthorized from "../features/unauthorized/unauthorized";
import GuestPageLayout from "../layout/guestPageLayout";
import LoggedInPageLayout from "../layout/loggedInPageLayout";

// Template for a route
// {
//   path: '/login',
//   name: "Login",
//   component: Login,
//   authenticated: false,
//   roles: [],
//   children: [],
//   exact: true,
//   layout: LoggedInPageLayout
// },

const defaultCrudChildren = [
  { path: "/details/:id", name: "Details" },
  { path: "/store/:id", name: "Edit" },
];

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: GuestPageLayout,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    layout: GuestPageLayout,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    layout: GuestPageLayout,
  },
  {
    path: "/set-password",
    name: "SetPassword",
    component: SetPassword,
    layout: GuestPageLayout,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: SetPassword,
    layout: GuestPageLayout,
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: Unauthorized,
    authenticated: true,
    roles: [],
    layout: GuestPageLayout,
  },
  {
    path: "/",
    name: "Search",
    exact: true,
    component: Search,
    authenticated: true,
    layout: LoggedInPageLayout,
  },
  {
    path: "/saved-reports",
    name: "Saved Reports",
    exact: true,
    component: SavedReports,
    authenticated: true,
    layout: LoggedInPageLayout,
    permissions: [K.Permissions.ViewReports, K.Permissions.ViewOwnReports],
  },
  {
    path: "/saved-reports/view/:id",
    name: "Saved Reports",
    exact: true,
    component: ViewSavedReport,
    authenticated: true,
    layout: LoggedInPageLayout,
    permissions: [K.Permissions.ViewReports, K.Permissions.ViewOwnReports],
  },
  {
    path: "/settings/roles",
    name: "Roles",
    exact: true,
    component: Roles,
    authenticated: false,
    layout: LoggedInPageLayout,
    permissions: [K.Permissions.SuperAdmin],
  },
  {
    path: "/settings/manage-users",
    name: "Manage Users",
    exact: true,
    component: ManageUsers,
    authenticated: true,
    layout: LoggedInPageLayout,
    permissions: [K.Permissions.ViewUserListing],
  },
  /* {
    path: "/settings/permissions",
    name: "Permissions",
    exact: true,
    component: Permissions,
    authenticated: true,
    layout: LoggedInPageLayout,
    permissions: [K.Permissions.SuperAdmin],
  }, */
  {
    path: "/settings/profile",
    name: "Profile",
    exact: true,
    component: Profile,
    authenticated: true,
    layout: LoggedInPageLayout,
  },
  {
    path: "/clients",
    name: "Clients",
    exact: true,
    component: Clients,
    authenticated: true,
    layout: LoggedInPageLayout,
    permissions: [K.Permissions.SuperAdmin],
  },
  {
    path: "*",
    name: "Not Found",
    component: NotFound,
    layout: GuestPageLayout,
  },
];

export default routes;
