import React, { Fragment, useEffect } from "react";
import {
  PageHeader,
  Card,
  Avatar,
  Typography,
  Row,
  Col,
  Space,
  Divider,
  Button,
  Form,
  Input,
} from "antd";
import User from "../../models/user/user";
import { useDispatch } from "react-redux";
import {
  redirectToUrl,
  validatePasswordField,
} from "../../utilities/generalUtility";
var md5 = require("md5");

const { Title } = Typography;

export default function EditProfile({ handleEdit }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue({
      firstName: User.getFirstName(),
      lastName: User.getLastName(),
      email: User.getEmail(),
    });
  }, []);

  const onSubmit = async (values) => {
    delete values.confirmPassword;
    delete values.email;
    const { password } = values;
    if (password) values.password = md5(password);
    try {
      const res = await dispatch(User.updateUserProfile(values));
      redirectToUrl("", "/settings/profile");
    } catch (error) {
      console.error(error);
    }
    // handleEdit();
  };
  return (
    <Fragment>
      <PageHeader
        title="Profile"
        className="bg-white d-flex justify-content-between align-items-center"
      >
        <Button
          className="mr-3"
          size="large"
          key="1"
          type="ghost"
          onClick={() => {
            handleEdit();
          }}
        >
          Cancel
        </Button>
        <Button
          size="large"
          key="1"
          type="primary"
          onClick={() => {
            form.submit();
          }}
        >
          Save
        </Button>
      </PageHeader>
      <div className="cercadian-content-area p-3">
        <Card className="h-100 bg-white profile-card">
          <Space className="w-100" direction="vertical" size={30}>
            <Row>
              <Col xs={24} xl={24} className="align-items-center">
                <Avatar
                  className="mr-4"
                  size={64}
                  src={User.getPictureUrl()}
                  // icon={<UserOutlined />}
                />
              </Col>
            </Row>
            <Form
              form={form}
              layout="vertical"
              name="basic"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={onSubmit}
              autoComplete="off"
              className="edit-pro-form"
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name!",
                      },
                      {
                        min: 3,
                        message: "Name must be atleast 3 characters long!",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="First Name" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Last Name"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name!",
                      },
                      {
                        min: 3,
                        message: "Name must be atleast 3 characters long!",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Last Name" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      { required: false, message: "Please input your Email!" },
                    ]}
                  >
                    <Input size="large" placeholder="Email" disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Divider plain className="m-0"></Divider>
              <Title className="mt-3" level={5}>
                Change Password
              </Title>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    label="New Password"
                    name="password"
                    rules={[
                      {
                        min: 6,
                        message: "Password must be atleast 6 characters long!",
                      },
                    ]}
                  >
                    <Input.Password size="large" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Confirm New Password"
                    name="confirmPassword"
                    rules={[
                      () => ({
                        validator(rule, value) {
                          return validatePasswordField(
                            value,
                            form.getFieldValue("password")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password size="large" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Space>
        </Card>
      </div>
    </Fragment>
  );
}
