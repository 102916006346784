import React, { useState } from "react";

import { PageHeader, Button, Card, Table, Space, Layout, Input, Modal, Typography, Descriptions, Select } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from '@ant-design/icons';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
//import Title from "antd/lib/skeleton/Title";

const { Title } = Typography;

const { Content } = Layout;

const { Option } = Select;

const children = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const dataSource = [
  {
    key: '1',
    name: 'John',
    email: 'kenzi.lawson@example.com',
  },
  {
    key: '2',
    name: 'John Chow',
    email: 'michael.mitc@example.com',
  },
  {
    key: '3',
    name: 'Nick Diaz',
    email: 'debbie.baker@example.com',
  },
  {
    key: '4',
    name: 'loey Diaz',
    email: 'alma.lawson@example.com',
  },
  {
    key: '5',
    name: 'Andrew Chan',
    email: 'georgia.young@example.com',
  },
];


export default function Permissions() {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  let searchInput = "";

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder="Search Name"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              handleReset(clearFilters)
            }
            size="small"
            style={{ width: 90 }}
          >
            Clear
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: filtered ? "#1890ff" : undefined, fontSize: 16 }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps("name"),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps("email"),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '20%',
      render: (value) => {
        return <Router>
          <Space size="middle">
            <Link to="/" onClick={showModal}>Assign Roles</Link>
          </Space>
        </Router>
      }

    },
  ];

  return (
    <>
      <PageHeader title="Permissions" className="bg-white d-flex justify-content-between align-items-center">
      </PageHeader>
      <div className="cercadian-content-area p-3">
        <Card className="card-box bg-white pt-0">
          <Table className="cercadian-table" dataSource={dataSource} columns={columns} />
        </Card>
      </div>
      <Modal
        okText={'Save'}
        className="cr-modal" title="Assign Roles" width={404} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Descriptions className="user-mail" title="User Name:">
          <Descriptions.Item label="Nick Diaz">nickdiaz@mail.com</Descriptions.Item>
        </Descriptions>
        <Title level={5} className="heading-2">Select Role</Title>
        <Select mode="tags" size="large" style={{ width: '100%' }} tokenSeparators={[',']}>
          {children}
        </Select>
      </Modal>
    </>
  );
}