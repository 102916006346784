import K from "../utilities/constants";
import Cookies from "js-cookie";
import User from "../models/user/user";

export default class Request {
  constructor(
    relativeURL,
    method = K.Network.Method.GET,
    body = null,
    defaultHeaderType = K.Network.Header.Type.Json,
    headers = {},
    isTenant = true
  ) {
    const token = User.getToken();
    const permissions = User.getAllPermissions();
    const domainPrefix = Cookies.get(K.Cookie.Key.Tenant);
    headers = {
      ...(defaultHeaderType === K.Network.Header.Type.Json
        ? K.Network.Header.Default(token, permissions)
        : K.Network.Header.Authorization(token, permissions)),
      ...headers,
    };
    this.url = isTenant
      ? K.Network.URL.TenantURL(domainPrefix) + relativeURL
      : K.Network.URL.BaseAPI + relativeURL;
    this.method = method;
    this.body = body;
    this.headers = headers;
  }

  // Tenant calls.
  static getTenant() {
    return new Request(K.Network.URL.GetTenant, K.Network.Method.GET);
  }

  // User calls.
  static loginUser(email, password) {
    const body = {
      email,
      password,
    };
    return new Request(
      K.Network.URL.LoginUser,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static forgotPassword(email) {
    const body = {
      email,
    };
    return new Request(
      K.Network.URL.ForgotPassword,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static resetPassword(password, token) {
    const body = {
      password,
    };
    return new Request(
      K.Network.URL.ResetPassword + `?token=${token}`,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static setPassword(password, token) {
    const body = {
      password,
    };
    return new Request(
      K.Network.URL.SetPassword + `?token=${token}`,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static getXml(XML) {
    // const body = {
    //   XML,
    // };
    return new Request(
      K.Network.URL.WebCaseMessage,
      K.Network.Method.POST,
      XML,
      K.Network.Header.Type.XML,
      { "Content-Type": "application/xml", Accept: "application/xml" },
      false
    );
  }
  
  static getXmlFromFile(file) {
    const formData = new FormData();
    formData.append('file', file);

    return new Request(
      K.Network.URL.WebCasFile,
      K.Network.Method.POST,
      formData,
      K.Network.Header.Type.XML,
      { "Content-Type": "multipart/form-data", Accept: "application/xml" },
      false
    );
  }

  static getSavedReports() {
    return new Request(
      K.Network.URL.SavedReports,
      K.Network.Method.GET,
      null,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static deleteSavedReport(id) {
    const body = {};
    return new Request(
      K.Network.URL.SavedReports + `/${id}`,
      K.Network.Method.DELETE,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static renameSavedReport(id, name) {
    const body = { name };
    return new Request(
      K.Network.URL.SavedReports + `/${id}`,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static createSavedReport(name, data) {
    const body = { name, data };
    return new Request(
      K.Network.URL.SavedReports,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static getReportById(id) {
    return new Request(
      K.Network.URL.SavedReports + `/${id}`,
      K.Network.Method.GET,
      null,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static getRoles() {
    return new Request(
      K.Network.URL.Roles,
      K.Network.Method.GET,
      null,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static deleteRole(id) {
    const body = {};
    return new Request(
      K.Network.URL.Roles + `/${id}`,
      K.Network.Method.DELETE,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static getRoleById(id) {
    return new Request(
      K.Network.URL.Roles + `/${id}`,
      K.Network.Method.GET,
      null,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static createRole(name, permissions) {
    const body = { name, permissions };
    return new Request(
      K.Network.URL.Roles,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static editRole(id, name, permissions) {
    const body = { name, permissions };
    return new Request(
      K.Network.URL.Roles + `/${id}`,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static sendInvite(userData) {
    const body = { ...userData };
    return new Request(
      K.Network.URL.SendInvite,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static sendClientInvite(clientData) {
    const body = { ...clientData };
    return new Request(
      K.Network.URL.SendClientInvite,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static updateUserProfile(values) {
    const body = { ...values };
    return new Request(
      K.Network.URL.User,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static getAllUsers() {
    return new Request(
      K.Network.URL.User,
      K.Network.Method.GET,
      null,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static getAllClients() {
    return new Request(
      K.Network.URL.ClientUser,
      K.Network.Method.GET,
      null,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static deleteUser(id) {
    const body = {};
    return new Request(
      K.Network.URL.User + `/${id}`,
      K.Network.Method.DELETE,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static getPermissions() {
    return new Request(
      K.Network.URL.Permission,
      K.Network.Method.GET,
      null,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static changeUserPassword(id, password) {
    const body = { password };
    return new Request(
      K.Network.URL.ChangeUserPassword + `/${id}`,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static updateUserRole(id, roleId) {
    const body = { roleId };
    return new Request(
      K.Network.URL.UpdateUserRole + `/${id}`,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static updateCompanyName(id, companyName) {
    const body = { companyName };
    return new Request(
      K.Network.URL.UpdateCompany + `/${id}`,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
}
