import React from "react";
import { Layout, Menu, Dropdown, Avatar } from "antd";
import {
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import User from "../models/user/user";
import Circadianlogo from "../assets/images/circadian-header-logo.png";

export default function Header({ collapsed, toggle }) {
  const { Header } = Layout;
  const location = useLocation();

  const menu = (
    <Menu>
      {/* <Menu.Item key="0">
        <Link to="/profile">Profile</Link>
      </Menu.Item>
      <Menu.Divider /> */}
      <Menu.Item key="0" onClick={() => User.logoutCall()}>
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <Header className="d-flex justify-content-between main-header bg-white">
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: "responsive-collapse-icon",
        onClick: toggle,
      })}
      <div className="ant-layout-sider-logo d-flex align-items-center">
        <Link to="/">
          <img src={Circadianlogo} alt="Circadianlogo" />
        </Link>
      </div>

      <div className="app-header-right">
        <div className="loggedin-user-dd">
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            placement="topRight"
            arrow
          >
            <a
              href="#menu"
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <Avatar src={User.getPictureUrl()} />
              {User.getFirstName().concat(" ", User.getLastName())}
              <DownOutlined />
            </a>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
}
