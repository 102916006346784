import React, { useState } from "react";
import { SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  message,
  Modal,
  Row,
} from "antd";

import { useDispatch } from "react-redux";
import { getXmlFromFile } from "../../api/api";
import DataImage from "../../assets/images/HOW-TO@1x.png";
import SavedReport from "../../models/savedReport/savedReport";
import SearchTable from "./searchTable";
import ExportCSV from "../../common/components/exportCSV/exportCSV";
import { isPermissionPresent } from "../../utilities/generalUtility";
import K from "../../utilities/constants";
import ReportChart from "../reportChart/reportChart";


export default function Search() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState("");
  const [CSVData, setCSVData] = useState([]);
  const [CSVColumns, setCSVColumns] = useState([]);
  const [emptyError, setEmptyError] = useState(false);  
  const [file, setFile] = useState()
  const [fileName, setFileName] = useState('');

  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleXmlSearch = async () => {
    if (file) {
      try {
        const responseData = await getXmlFromFile(file);
        setData(responseData.get_fatigue_report);
        setFileName(file.name);
      } catch (error) {
        console.log("error", error);
      }
    } else {
      setEmptyError(true);
    }
  };
  const saveReport = async (values) => {
    try {
      await dispatch(SavedReport.createReport(values.name, data));
      message.success("Report Saved Successfully!");
      setIsModalVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const mappedFatigueScores = () => {
    if (data && data.sequences.sequence && !Array.isArray(data.sequences.sequence)) {
      return [Number(data.sequences.sequence.FatigueScore)];
    }

    if (!data || data.length === 0) {
      return [];
    }
    
    return data.sequences.sequence.map(item => Number(item.FatigueScore));
  }

  return (
    <div className="p-3 h-100 w-100">
      <Row gutter={16}>
        <Col
          className="gutter-row"
          xs={22}
          sm={22}
          md={22}
          lg={6}
          xl={6}
          xxl={6}
        >
          <Card title="Upload File" className="search-card" bordered={false}>
            <Input
              type="file" 
              onChange={handleChange}
            />
            {emptyError && (
              <span className="invalid-xml">Please enter a valid XML</span>
            )}

            <Button
              type="primary"
              size="large"
              onClick={handleXmlSearch}
              className="search-btn"
            >
              Submit
            </Button>
          </Card>
          <Card className="text-card">
            <p>
              <strong>
                Only XML and CSV Files can be uploaded<br />
                <a href={process.env.REACT_APP_INSTRUCTIONS_URL} target="_blank" rel="noreferrer">Click here for instructions</a>
              </strong>
            </p>
            <p>
              <strong>About CAS</strong><br />
              CAS is a scientifically validated fatigue risk model that converts work history data into Fatigue Scores ranging from 1 to 100:<br />
              <ul> 
                <li>1-30  = Low Fatigue Risk</li>
                <li>31-55 = Average Fatigue Risk</li>
                <li>56-100 = High Fatigue Risk</li>
              </ul>
            </p>
            <p>For over 20-years, 24/7 operations have used CAS to assess the fatigue risk of each person in their workforce and drive down the daily risk of errors, accidents and injuries due to worker fatigue.</p>
            <p><strong>CIRCADIAN®</strong> is the global leader in scheduling, staffing, training and risk management of the 24/7 workforce.</p>
            <p>Learn more at <a href="https://www.circadian.com" target="_blank" rel="noreferrer">www.circadian.com</a></p>
          </Card>
        </Col>
        <Col
          className="gutter-row"
          span={26}
          xs={26}
          sm={26}
          md={26}
          lg={18}
          xl={18}
          xxl={18}
        >
          {data && data.sequences ? (
            <Card className="card-box results-card">
              <div className="mt-2 d-flex justify-content-between align-items-center">
                <h2 className="mb-0">Result</h2>
                <div className="d-flex">
                  {isPermissionPresent([K.Permissions.SaveReports]) && (
                    <Button
                      className="mt-0 mr-2"
                      onClick={() => {
                        setIsModalVisible(true);
                      }}
                    >
                      <SaveOutlined />
                    </Button>
                  )}

                  <ExportCSV
                    fileName="generated_report"
                    tableData={CSVData}
                    columns={CSVColumns}
                  />
                </div>
              </div>
              <ReportChart fileName={fileName} reportData={data} />
              <SearchTable
                data={data}
                setCSVData={setCSVData}
                setCSVColumns={setCSVColumns}
              />
            </Card>
          ) : (
            <Card className="d-flex results-card">
              <h4>CAS Fatigue Risk Management</h4>
              
              <div>
                <Image style={{width: "100%"}} preview={false} src={DataImage} />
              </div>
              
              <div className="instructions-aside">
                <aside>
                  <h5>Common CAS Fatigue Risk Applications</h5>

                  <div className="applications-list">
                    <ul>
                      <li>Assess Current or Proposed Schedules</li>
                      <li>Weekly Report to Identity High Fatigue Risk</li>
                      <li>Make Decisions About Overtime Assignments</li>
                      <li>Assess Shift Swaps</li>
                    </ul>
                  </div>
                  
                  <div className="applications-list">
                    <ul>
                    <li>Benchmark Monthly Fatigue Scores</li>
                      <li>Analyze Incident/Accident/Workers Comp</li>
                      <li>Balance Staffing Levels and Fatigue Risk</li>
                      <li>Risk-Informed Planning and Modeling</li>
                    </ul>
                  </div>
                </aside>
              </div>
            </Card>
          )}
        </Col>
      </Row>
      <Modal
        title="Save Report"
        visible={isModalVisible}
        okText="Save"
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} onFinish={saveReport}>
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Please enter report name" }]}
          >
            <Input placeholder="Enter report name" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
