import {
  ProjectOutlined,
  SearchOutlined,
  ProfileOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import K from "../utilities/constants";

// Template a navigation item
// {
//     name: 'User',
//     path: '/user/list',
//     icon: <ProjectOutlined />,
//     roles: [],
//     children: [], // If item has children, then the path field will be ignored.
// }

const defaultChildren = (basePath) => [
  { path: `${basePath}/default`, name: "Default", permissions: "" },
];

const settingsChildren = (basePath) => [
  {
    path: `${basePath}/profile`,
    name: "Profile",
    permissions: [],
  },
  {
    path: `${basePath}/manage-users`,
    name: "Manage Users",
    permissions: [K.Permissions.ViewUserListing],
  },
  {
    path: `${basePath}/roles`,
    name: "Roles",
    permissions: [K.Permissions.SuperAdmin],
  },
  /* {
    path: `${basePath}/permissions`,
    name: "Permissions",
    permissions: [K.Permissions.SuperAdmin],
  }, */
];

const navigations = [
  {
    name: "Search",
    path: "/",
    icon: <SearchOutlined />,
    permissions: "",
  },
  {
    name: "Saved Reports",
    path: "/saved-reports",
    icon: <ProfileOutlined />,
    permissions: [K.Permissions.ViewReports, K.Permissions.ViewOwnReports],
  },
  {
    name: "Clients",
    path: "/clients",
    icon: <TeamOutlined />,
    permissions: [K.Permissions.SuperAdmin],
  },
  {
    name: "Settings",
    // path: "/settings",
    icon: <ProjectOutlined />,
    permissions: "",
    children: settingsChildren("/settings"),
  },
];

export default navigations;
