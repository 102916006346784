import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import ExportCSV from "../../common/components/exportCSV/exportCSV";
import { completeDateFormat } from "../../utilities/dateUtility";
import { stringSorting } from "../../utilities/generalUtility";
import GanttChart from "./ganttChart";

export default function SearchTable({ data, setCSVData, setCSVColumns }) {
  let searchInput = "";
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [isModalVisible, setisModalVisible] = useState(false);
  const [ganttData, setGanttData] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (data) {
      if (data.sequences.sequence.length > 1) {
        const tempData = Object.keys(data.sequences.sequence).map((key) => ({
          ...data.sequences.sequence[key],
          local_start: completeDateFormat(
            data.sequences.sequence[key].local_start
          ),
          local_end: completeDateFormat(data.sequences.sequence[key].local_end),
        }));
        setDataSource(tempData);
        if (setCSVData) setCSVData(tempData);
      } else {
        const tempData = Object.keys(data.sequences).map((key) => ({
          ...data.sequences[key],
          local_start: completeDateFormat(data.sequences[key].local_start),
          local_end: completeDateFormat(data.sequences[key].local_end),
        }));
        setDataSource(tempData);
        if (setCSVData) setCSVData(tempData);
      }
    }
  }, [data]);
  useEffect(() => {
    if (setCSVColumns) setCSVColumns(columns);
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const handleModal = (record) => {
    setGanttData(record);
    setisModalVisible(true);
    setTitle(record.name);
  };

  const handleClose = () => {
    setisModalVisible(false);
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder="Search Name"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Clear
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: filtered ? "#1890ff" : undefined, fontSize: 16 }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      sorter: {
        compare: (a, b) => parseInt(a.id) - parseInt(b.id),
        multiple: 1
      },
      sortDirections: ["ascend", "descend"],
      ...getColumnSearchProps("id"),
      defaultSortOrder: "ascend"
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => stringSorting(a, b, "name"),
      sortDirections: ["ascend", "descend"],
      ...getColumnSearchProps("name"),
    },

    {
      title: "Fatigue Score",
      dataIndex: "FatigueScore",
      key: "FatigueScore",
      sorter: {
        compare: (a, b) => parseFloat(a.FatigueScore) - parseFloat(b.FatigueScore),
        multiple: 2
      },
      sortDirections: ["ascend", "descend"],
      ...getColumnSearchProps("FatigueScore"),
      defaultSortOrder: "descend"
    },
    {
      title: "Start Date",
      dataIndex: "local_start",
      key: "local_start",
      sorter: (a, b) => stringSorting(a, b, "local_start"),
      sortDirections: ["ascend", "descend"],
      ...getColumnSearchProps("local_start"),
    },

    {
      title: "End Date",
      dataIndex: "local_end",
      key: "local_end",
      sorter: (a, b) => stringSorting(a, b, "local_end"),
      sortDirections: ["ascend", "descend"],
      ...getColumnSearchProps("local_end"),
    },
  ];
  const GanttCsvColumns = [
    {
      title: "Start",
      dataIndex: "local_start",
      key: "local_start",
    },
    {
      title: "End",
      dataIndex: "local_end",
      key: "local_end",
    },

    {
      title: "Average Alertness",
      dataIndex: "AverageAlertness",
      key: "AverageAlertness",
    },
  ];

  return (
    <div style={{ marginTop: "50px" }}>
      <Table
        rowClassName={(record, index) => {
          if (record.FatigueScore >= 0 && record.FatigueScore <= 30) {
            return "FatigueScore-0-30";
          }
          if (record.FatigueScore > 30 && record.FatigueScore <= 60) {
            return "FatigueScore-30-60";
          }
          if (record.FatigueScore > 60) {
            return "FatigueScore-Above-60";
          }
        }}
        className="cercadian-table"
        dataSource={dataSource}
        columns={columns}
        onRow={(record, index) => {
          return {
            onClick: (event) => {
              handleModal(record);
            },
          };
        }}
      />
     
      <Modal
        title={
          <div className=" d-flex justify-content-between align-items-center">
            {title}
            <ExportCSV
              tableData={ganttData?.tasks?.task}
              columns={GanttCsvColumns}
              fileName={title}
            />
          </div>
        }
        visible={isModalVisible}
        okText={"Close"}
        onOk={handleClose}
        cancelButtonProps={{ style: { display: "none" } }}
        width={1000}
        closable={false}
      >
        <GanttChart data={ganttData} />
      </Modal>
    </div>
  );
}
