import React from "react";
import { ExportTableButton } from "ant-table-extensions";
import { DownloadOutlined } from "@ant-design/icons";

export default function ExportCSV({ tableData, columns, fileName }) {
  const CSVColumns = (columns) => {
    const fields = {};
    columns.forEach((item) => {
      fields[item.dataIndex] = {
        header: item.title,
        formatter: (value) => {
          return value;
          /* if (record[item.dataIndex] == 0) {
            return "0";
          } else return record[item.dataIndex]; */
        },
      };
    });
    return fields;
  };

  return (
    <ExportTableButton
      dataSource={tableData}
      columns={columns}
      btnProps={{ className: "mt-0" }}
      fileName={fileName}
      fields={CSVColumns(columns)}
    >
      <DownloadOutlined />
    </ExportTableButton>
  );
}
