import NetworkCall from "../network/networkCall";
import DirectNetworkCall from "../network/directNetworkCall"
import Request from "../network/request";
import DirectRequest from "../network/directRequest"

export const getXml = async (XML) =>
  await NetworkCall.fetch(Request.getXml(XML));

  
export const getXmlFromFile = async (file) =>
  await DirectNetworkCall.fetch(DirectRequest.getXmlFromFile(file));
