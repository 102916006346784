import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Space,
  Table,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import SavedReport from "../../models/savedReport/savedReport";
import BaseModel from "../../models/baseModel/baseModel";
import { stringSorting } from "../../utilities/generalUtility";
import { Link } from "react-router-dom";

export default function SavedReports() {
  let searchInput = "";
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [editId, setEditId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState("");
  const reportsList = useSelector((state) => {
    return BaseModel.getSelector(SavedReport)(state);
  });
  
  const getSavedReports = async () => {
    try {
      await dispatch(SavedReport.getReports());
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteReport = async (id) => {
    try {
      await dispatch(SavedReport.deleteReport(id));
      message.success("Report Deleted");
    } catch (error) {
      console.error(error);
    }
  };

  const handleOk = async (values) => {
    setIsLoading(true);
    try {
      await dispatch(SavedReport.renameReport(editId, values.name));
      message.success("Report name updated");
      setIsModalVisible(false);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder="Search Name"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Clear
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: filtered ? "#1890ff" : undefined, fontSize: 16 }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    getSavedReports();
  }, []);

  const columns = [
    {
      title: "Report Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => stringSorting(a, b, "name"),
      ...getColumnSearchProps("name"),
    },
    {
      title: "Saved By",
      dataIndex: "savedBy",
      key: "savedBy",
      sorter: (a, b) => stringSorting(a, b, "savedBy"),
      ...getColumnSearchProps("savedBy"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "20%",
      render: (value, record) => {
        return (
          <Space size="middle">
            <Button type="link">
              <Link to={`/saved-reports/view/${record.id}`}>View</Link>
            </Button>
            |
            <Button
              type="link"
              onClick={() => {
                setIsModalVisible(true);
                form.setFieldsValue({ name: record.name });
                setEditId(record.id);
              }}
            >
              Rename
            </Button>
            |
            <Popconfirm
              title="Are you sure to delete this report?"
              onConfirm={() => {
                deleteReport(record.id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link">Delete</Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  return (
    <Fragment>
      <PageHeader
        title="Saved Reports"
        className="bg-white d-flex justify-content-between align-items-center"
      ></PageHeader>
      <div className="cercadian-content-area p-3">
        <Card className="card-box bg-white pt-0">
          <Table
            className="cercadian-table"
            dataSource={reportsList}
            columns={columns}
          />
        </Card>
      </div>
      <Modal
        title="Rename Report"
        visible={isModalVisible}
        onOk={() => {
          form.submit();
        }}
        okButtonProps={{ loading: isLoading }}
        onCancel={handleCancel}
      >
        <Form form={form} onFinish={handleOk}>
          <Form.Item
            className="form-item-modal"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input report name!",
              },
            ]}
          >
            <Input size="middle" placeholder="Report Name" />
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
}
