import { attr } from "redux-orm";
import BaseModel from "../baseModel/baseModel";
import NetworkCall from "../../network/networkCall";
import Request from "../../network/request";
import baseReducer from "../baseModel/baseReducer";
import {
  deleteAllModels,
  deleteModel,
  upsertModel,
  upsertModels,
} from "../baseModel/baseActions";
import K from "../../utilities/constants";
import Cookies from "js-cookie";
import { redirectToLogin } from "../../utilities/generalUtility";
import CryptoJS from "crypto-js";

export default class User extends BaseModel {
  // API call using thunk.
  static loginCall(email, password, remember) {
    return async (dispatch) => {
      const user = await NetworkCall.fetch(Request.loginUser(email, password));

      let encryptedUser = CryptoJS.AES.encrypt(
        JSON.stringify(user),
        K.Cookie.Key.EncryptionKey
      );
      
      var domain = K.Network.URL.Client.BaseHost !== "localhost"
        ? "." + K.Network.URL.Client.BaseHost
        : K.Network.URL.Client.BaseHost;

      Cookies.set(K.Cookie.Key.User, encryptedUser, {
        path: "/",
        domain: domain,
        expires: remember ? 365 : "",
      });

      dispatch(upsertModel(User, user));
      return user;
    };
  }

  static logoutCall(error = "") {      
    var domain = K.Network.URL.Client.BaseHost !== "localhost"
      ? "." + K.Network.URL.Client.BaseHost
      : K.Network.URL.Client.BaseHost;
    
    Cookies.remove(K.Cookie.Key.User, {
      path: "/",
      domain: domain,
    });
    redirectToLogin(error);
  }

  //Forgot password
  static async forgotPassword(email) {
    const user = await NetworkCall.fetch(Request.forgotPassword(email));
    console.log("User: ", user);
    return user;
  }

  //Reset password
  static resetPassword(email, token, remember) {
    return async (dispatch) => {
      const user = await NetworkCall.fetch(Request.resetPassword(email, token));

      let encryptedUser = CryptoJS.AES.encrypt(
        JSON.stringify(user),
        K.Cookie.Key.EncryptionKey
      );
      console.log(encryptedUser);
      Cookies.set(K.Cookie.Key.User, encryptedUser, {
        path: "/",
        domain: "." + K.Network.URL.Client.BaseHost,
        expires: remember ? 365 : "",
      });

      dispatch(upsertModel(User, user));

      return user;
    };
  }

  static setPassword(email, token, remember) {
    return async (dispatch) => {
      const user = await NetworkCall.fetch(Request.setPassword(email, token));

      let encryptedUser = CryptoJS.AES.encrypt(
        JSON.stringify(user),
        K.Cookie.Key.EncryptionKey
      );
      Cookies.set(K.Cookie.Key.User, encryptedUser, {
        path: "/",
        domain: "." + K.Network.URL.Client.BaseHost,
        expires: remember ? 365 : "",
      });

      dispatch(upsertModel(User, user));
      return user;
    };
  }

  static async sendInvite(userData) {
    const res = await NetworkCall.fetch(Request.sendInvite(userData), false);
    return res;
  }

  static async sendClientInvite(clientData) {
    const res = await NetworkCall.fetch(
      Request.sendClientInvite(clientData),
      false
    );
    return res;
  }

  static updateUserProfile(values) {
    return async (dispatch) => {
      const res = await NetworkCall.fetch(Request.updateUserProfile(values));
      const { success, ...obj } = res;
      obj.token = User.getToken();
      obj.user.role = User.getRoles();
      let encryptedUser = CryptoJS.AES.encrypt(
        JSON.stringify(obj),
        K.Cookie.Key.EncryptionKey
      );
      Cookies.set(K.Cookie.Key.User, encryptedUser, {
        path: "/",
        domain: "." + K.Network.URL.Client.BaseHost,
      });
      dispatch(upsertModel(User, obj));
      return res;
    };
  }

  static getAll() {
    return async (dispatch) => {
      const list = await NetworkCall.fetch(Request.getAllUsers());
      dispatch(deleteAllModels(User));
      dispatch(
        upsertModels(
          User,
          list.map((item) => ({
            ...item,
            name: item.firstName.concat(" ", item.lastName),
            role: item.role?.name,
            roleId: item.role?.id,
          }))
        )
      );
      return list;
    };
  }

  static getAllClients() {
    return async (dispatch) => {
      const list = await NetworkCall.fetch(Request.getAllClients());
      dispatch(deleteAllModels(User));
      dispatch(
        upsertModels(
          User,
          list.map((item) => ({
            ...item,
            key: item.id,
            name: item.firstName.concat(" ", item.lastName),
            role: item.role.name,
            roleId: item.role.id,
            companyId: item.company.id,
            company: item.company.name,
          }))
        )
      );
      return list;
    };
  }
  static deleteUser(userId) {
    return async (dispatch) => {
      const res = await NetworkCall.fetch(Request.deleteUser(userId));
      dispatch(deleteModel(User, userId));
    };
  }

  static async changePassword(id, password) {
    const res = await NetworkCall.fetch(
      Request.changeUserPassword(id, password),
      false
    );
    return res;
    // dispatch(deleteModel(User, userId));
  }

  static updateUserRole(id, roleId) {
    return async (dispatch) => {
      const res = await NetworkCall.fetch(
        Request.updateUserRole(id, roleId),
        false
      );
      const { user } = res;
      dispatch(
        upsertModel(User, {
          ...user,
          key: user.id,
          name: user.firstName.concat(" ", user.lastName),
          role: user.role.name,
          roleId: user.role.id,
        })
      );
      return res;
    };
  }

  static async updateCompanyName(id, companyName) {
    console.log({ id, companyName });
    const res = await NetworkCall.fetch(
      Request.updateCompanyName(id, companyName),
      false
    );
    return res;
  }
  // Selectors

  // Helpers
  static getUserObjectFromCookies() {
    let cookieUser = Cookies.get(K.Cookie.Key.User);
    let bytes = cookieUser
      ? CryptoJS.AES.decrypt(cookieUser, K.Cookie.Key.EncryptionKey)
      : "{}";
    try {
      let utfBytes = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(utfBytes);
    } catch (error) {
      console.log("error", error);
      return this.logoutCall("User unauthorized");
    }
  }

  static isTokenAvailable() {
    return this.getUserObjectFromCookies().token ? true : false;
  }

  static getTenant() {
    return this.getUserObjectFromCookies().tenant?.domainPrefix ?? null;
  }

  static getToken() {
    return this.getUserObjectFromCookies().token ?? "";
  }

  static getFirstName() {
    return this.getUserObjectFromCookies().user?.firstName ?? "";
  }

  static getLastName() {
    return this.getUserObjectFromCookies().user?.lastName ?? "";
  }

  static getEmail() {
    return this.getUserObjectFromCookies().user?.email ?? "";
  }
  static getRoles() {
    return this.getUserObjectFromCookies().user?.role ?? "";
  }
  static getCompany() {
    return this.getUserObjectFromCookies().user?.company ?? "";
  }
  static getAllPermissions() {
    const role = this.getRoles();
    let permissions = [];
    for (const obj of role.permissions ?? []) {
      permissions.push(obj.permissionCode);
    }
    return permissions;
  }

  static getPictureUrl() {
    return this.getUserObjectFromCookies().user?.pictureUrl ?? "";
  }
  // Reducer
  static reducer(action, User, session) {
    baseReducer(action, User, session);
  }
}

User.modelName = "User";

User.fields = {
  // Attributes
  id: attr(),
  firstName: attr(),
  lastName: attr(),
  name: attr(),
  email: attr(),
  cellPhone: attr(),
  officePhone: attr(),
  employeeNumber: attr(),
  fullTimeAvailabilityStartDate: attr(),
  fullTimeAvailabilityEndDate: attr(),
  targetUtilization: attr(),
  billRate: attr(),
  isCustomBillRate: attr(),
  photoPath: attr(),
  roleId: attr(),
  locationId: attr(),
  subscriptionId: attr(),
  dob: attr(),
  joiningDate: attr(),
  prefix: attr(),
  type: attr(),
};
