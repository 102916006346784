import React, { useEffect, useState } from "react";
import { DashboardOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { isPermissionPresent } from "../utilities/generalUtility";
import navigations from "./navigations";

export default function Sider({ collapsed, toggle }) {
  const { Sider } = Layout;
  const { SubMenu } = Menu;
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [openKeys, setOpenKeys] = useState(
    location.pathname.includes("settings") ? ["settings"] : []
  );
  useEffect(() => {
    const keys = location.pathname.split("/");
    if (keys.includes("settings")) {
      setOpenKeys([keys[1]]);
      setSelectedKeys([location.pathname]);
    } else {
      setSelectedKeys(["/" + keys[1]]);
    }
  }, []);
  return (
    <Sider
      className="side-nav"
      width={208}
      breakpoint="md"
      collapsedWidth="0"
    >
      <Menu
        selectedKeys={selectedKeys}
        mode="inline"
        openKeys={openKeys}
        onOpenChange={(keys) => {
          setOpenKeys(keys);
        }}
      >
        {navigations.map((navigation, i) => {
          const hasRole = isPermissionPresent(navigation.permissions);

          if (!hasRole) {
            return null;
          }

          if ((navigation.children?.length ?? 0) > 0) {
            return (
              <SubMenu
                key={"settings"}
                icon={<DashboardOutlined />}
                title={navigation.name}
              >
                {navigation.children.map((subNavigation, j) => {
                  const navHasRole = isPermissionPresent(
                    subNavigation.permissions
                  );
                  return navHasRole ? (
                    <Menu.Item key={subNavigation.path}>
                      <Link to={subNavigation.path}>{subNavigation.name}</Link>
                    </Menu.Item>
                  ) : null;
                })}
              </SubMenu>
            );
          } else {
            return (
              <Menu.Item key={navigation.path} icon={navigation.icon}>
                <Link to={navigation.path}>{navigation.name}</Link>
              </Menu.Item>
            );
          }
        })}
      </Menu>
    </Sider>
  );
}
