import React, { Fragment } from "react";
import {
  PageHeader,
  Card,
  Avatar,
  Typography,
  Row,
  Col,
  Descriptions,
  Space,
  Divider,
  Button,
} from "antd";
import { FormOutlined } from "@ant-design/icons";
import User from "../../models/user/user";
import K from "../../utilities/constants";

const { Title } = Typography;

export default function Profile({ handleEdit, user }) {
  return (
    <Fragment>
      <PageHeader
        title="Profile"
        className="bg-white d-flex justify-content-between align-items-center"
      >
        <Button size="large" key="1" type="primary" onClick={handleEdit}>
          <FormOutlined /> Edit
        </Button>
      </PageHeader>
      <div className="cercadian-content-area p-3">
        <Card className="h-100 bg-white profile-card">
          <Space direction="vertical" size={30}>
            <Row>
              <Col xs={24} className="d-flex align-items-center">
                <Avatar className="mr-4" size={64} src={User.getPictureUrl()} />
                <Title level={5} className="avt-title mb-0">
                  {User.getFirstName().concat(" ", User.getLastName())}
                </Title>
              </Col>
            </Row>

            <Descriptions className="profile-desc">
              <Descriptions.Item label="Email" span={2}>
                {User.getEmail()}
              </Descriptions.Item>
              <Descriptions.Item label="Company">
                {User.getCompany()?.name ?? K.NullValue}
              </Descriptions.Item>
            </Descriptions>
          </Space>
          <Divider plain></Divider>
          {/* <Title level={5}>Change Password</Title> */}
          <Title level={5} className="mt-0 font-14 secret-password">
            Password
          </Title>
        </Card>
      </div>
    </Fragment>
  );
}
