export const mean = (values) => {
    if (values.length === 0) {
        return 0;
    }

    var sum = values.reduce((partialSum, current) => partialSum + current, 0);

    return (sum / (values.length)).toFixed(2);
};

export const standardDeviation = (values) => {
    if (values.length === 0) {
        return 0;
    }

    let mean = values.reduce((partialSum, curr) => {
        return partialSum + curr;
    }, 0) / values.length;
       
    values = values.map((k) => {
        return (k - mean) ** 2;
    });
       
    let sum = values.reduce((partialSum, current)=> partialSum + current, 0);
      
    return Math.sqrt(sum / values.length);
};

export const standardError = (values) => {
    if (values.length < 2) {
        return 0;
    }

    let mean = values.reduce((partialSum, curr) => {
        return partialSum + curr;
    }, 0) / values.length;
       
    values = values.map((k) => {
        return (k - mean) ** 2;
    });
       
    let sum = values.reduce((partialSum, current)=> partialSum + current, 0);
    
    return Math.sqrt(sum / values.length) / Math.sqrt(values.length);
};