import { useEffect, useState } from "react";
import styles from "./reportChart.module.scss";
import { mean, standardDeviation, standardError } from "../../utilities/statistics";
import BarChart from "./barChart";

export default function ReportChart({ reportData, fileName }) {

    const mappedFatigueScores = () => {
        if (reportData && reportData.sequences && reportData.sequences.sequence && !Array.isArray(reportData.sequences.sequence)) {
          return [Number(reportData.sequences.sequence.FatigueScore)];
        }
    
        if (!reportData || reportData.length === 0) {
          return [];
        }
        
        return reportData.sequences.sequence.map(item => Number(item.FatigueScore));
      }

      return <div>
        <div style={{textAlign: "center"}}><h3>Fatigue Index Distribution{fileName && (` - ${fileName}`)}</h3></div>
        {mappedFatigueScores().length > 1 && 
          <div style={{textAlign: "center", marginBottom: "15px"}}>
            mean: {mean(mappedFatigueScores())}&nbsp;&nbsp;
            std_dev: {standardDeviation(mappedFatigueScores()).toFixed(2)}&nbsp;&nbsp;
            std_err: {standardError(mappedFatigueScores()).toFixed(2)}&nbsp;&nbsp;
            n={mappedFatigueScores().length}
          </div>
        }
        <div style={{position: "relative"}}>
          <div className={styles.legendItem}>
            <div className={`${styles.green} ${styles.legendItemColor}`}></div>
            <span>0-30 (Low)</span>
          </div>
          <div className={styles.legendItem}>
            <div className={`${styles.yellow} ${styles.legendItemColor}`}></div>
            <span>31-55 (Medium)</span>
          </div>
          <div className={styles.legendItem}>
            <div className={`${styles.red} ${styles.legendItemColor}`}></div>
            <span>56-100 (High)</span>
          </div>
        </div>
        
        <BarChart data={reportData} />
      </div>;

}