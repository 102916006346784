import React, { Fragment } from "react";
import { Form, Input, Button, Checkbox, Card } from "antd";
import { LockOutlined } from "@ant-design/icons";
import User from "../../models/user/user";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Circadianlogo from "../../assets/images/circadian-logo.png";
import {
  redirectToUrl,
  validatePasswordField,
} from "../../utilities/generalUtility";
var md5 = require("md5");

export default function SetPassword() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [form] = Form.useForm();
  const isReset = location.pathname === "/reset-password";

  const onFinish = async (values) => {
    let token = location.search.substring(7);
    if (!isReset) {
      try {
        let user = await dispatch(
          User.setPassword(md5(values.password), token, values.remember)
        );
        const { from } = location.state || { from: { path: "/" } };
        redirectToUrl("", from.path);
      } catch (error) {}
    } else {
      try {
        let user = await dispatch(
          User.resetPassword(md5(values.password), token, values.remember)
        );
        const { from } = location.state || { from: { path: "/" } };
        redirectToUrl("", from.path);
      } catch (error) {}
    }
  };

  const onFinishFailed = (errorInfo) => {};
  return (
    <Fragment>
      <div className="login-container">
        <Link to="/">
          <div className="lc-logo">
            <img src={Circadianlogo} alt="" />
          </div>
        </Link>
        <Card bordered={false} className="login-card">
          <h4>{isReset ? "Reset Password" : "Set Password"}</h4>
          <Form
            name="basic"
            form={form}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  min: 6,
                  message: "Password must be atleast 6 characters long!",
                },
              ]}
            >
              <Input.Password
                prefix={
                  <LockOutlined className="site-form-item-icon text-primary" />
                }
                placeholder="Password"
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              rules={[
                () => ({
                  validator(rule, value) {
                    return validatePasswordField(
                      value,
                      form.getFieldValue("password")
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={
                  <LockOutlined className="site-form-item-icon text-primary" />
                }
                placeholder="Confirm Password"
                size="large"
              />
            </Form.Item>

            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <Link to="/login" className="float-right forgot-password" href="">
                Login to your account
              </Link>
            </Form.Item>
            <Form.Item noStyle>
              <Button block size="large" type="primary" htmlType="submit">
                Done
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </Fragment>
  );
}
