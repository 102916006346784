import React, { Fragment } from "react";
import { Divider, Image, Input, Transfer, Typography } from "antd";
import TransferRight from "../../assets/images/transfer-right-arrow.svg";
import TransferLeft from "../../assets/images/transfer-left-arrow.svg";

const { Title } = Typography;

export default function CreateRole({
  roleName,
  setRoleName,
  permissionsList,
  targetKeys,
  setTargetKeys,
  selectedKeys,
  setSelectedKeys,
}) {
  const onChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  return (
    <Fragment>
      <Title className="heading-2 text-uppercase" level={5}>
        Role Name
      </Title>
      <Input
        value={roleName}
        size="large"
        placeholder="Role Name"
        onChange={(e) => {
          setRoleName(e.target.value);
        }}
      />
      <Divider />
      <Transfer
        dataSource={permissionsList.map((item) => ({
          key: item.id,
          title: item.permissionString,
        }))}
        titles={["Unassigned Permissions", "Assigned Permissions "]}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onChange={onChange}
        onSelectChange={onSelectChange}
        render={(item) => item.title}
        operations={[
          <Image src={TransferRight} preview={false} />,
          <Image src={TransferLeft} preview={false} />,
        ]}
      />
    </Fragment>
  );
}
