import React, { Fragment } from "react";
import { Descriptions, Divider, List } from "antd";

export default function ViewRole({ role }) {
  return (
    <Fragment>
      <Descriptions>
        <Descriptions.Item label="ROLL NAME">{role.name}</Descriptions.Item>
      </Descriptions>
      <Divider className="m-0" />
      <List
        header="Assigned Permissions"
        bordered
        dataSource={role.permissions.map(
          ({ permissionString }) => permissionString
        )}
        className="user-list"
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    </Fragment>
  );
}
