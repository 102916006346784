import {
  LockOutlined,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Space,
  Table,
  Row,
  Col,
} from "antd";
import React, { Fragment, useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import BaseModel from "../../models/baseModel/baseModel";
import User from "../../models/user/user";
import {
  stringSorting,
  validatePasswordField,
} from "../../utilities/generalUtility";
var md5 = require("md5");

export default function Clients() {
  let searchInput = "";
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [changeForm] = Form.useForm();
  const [companyForm] = Form.useForm();
  const [userId, setUserId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isCompanyModalVisible, setIsCompanyModalVisible] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const userList = useSelector((state) => {
    return BaseModel.getSelector(User)(state);
  });

  const onSubmit = async (values) => {
    setIsLoading(true);
    try {
      const res = await User.sendClientInvite(values);
      message.success(`An email has been sent to ${values.email}`);
      setIsInviteModalVisible(false);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const handleOk = () => {
    form.submit();
  };

  const changePassword = async (values) => {
    setIsLoading(true);
    try {
      const res = await User.changePassword(userId, md5(values.password));
      message.success(res.message);
      setIsPasswordModalVisible(false);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const changeCompanyName = async (values) => {
    setIsLoading(true);

    try {
      const res = await User.updateCompanyName(companyId, values.companyName);
      message.success(res.message);
      setIsCompanyModalVisible(false);
      getAllClients();
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const handleCancel = () => {
    setIsInviteModalVisible(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder="Search Name"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Clear
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: filtered ? "#1890ff" : undefined, fontSize: 16 }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const deleteUser = async (id) => {
    try {
      await dispatch(User.deleteUser(id));
      message.success("User Deleted!");
    } catch (error) {
      console.error(error);
    }
  };
  const getAllClients = async () => {
    try {
      const clients = await dispatch(User.getAllClients());
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllClients();
  }, []);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => stringSorting(a, b, "name"),
      sortDirections: ["ascend", "descend"],
      ...getColumnSearchProps("name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => stringSorting(a, b, "email"),
      render: (text) => {
        return searchedColumn === "email" ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        );
      },
      ...getColumnSearchProps("email"),
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      sorter: (a, b) => stringSorting(a, b, "company"),
      ...getColumnSearchProps("company"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      // width: "25%",
      align: "center",
      render: (value, record) => {
        const menu = (
          <Menu>
            <Menu.Item
              onClick={() => {
                changeForm.resetFields();
                setUserId(record.id);
                setIsPasswordModalVisible(true);
              }}
              key="1"
            >
              Change Password
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                companyForm.setFieldsValue({ companyName: record.company });
                setCompanyId(record.companyId);
                setIsCompanyModalVisible(true);
              }}
              key="2"
            >
              Change Company Name
            </Menu.Item>
            <Menu.Item key="3">
              <Popconfirm
                placement="bottom"
                title="Are you sure to delete this user?"
                onConfirm={() => {
                  deleteUser(record.id);
                }}
                okText="Yes"
                cancelText="No"
              >
                <div
                  onClick={(e) => e.stopPropagation()}
                  style={{ height: "100%", width: "100%" }}
                >
                  Delete
                </div>
              </Popconfirm>
            </Menu.Item>
          </Menu>
        );
        if (record.email !== User.getEmail())
          return (
            <Dropdown trigger={["click", "hover"]} overlay={menu}>
              <MoreOutlined />
            </Dropdown>

            // <Space size="middle">
            //   <Button
            //     type="link"
            //     onClick={() => {
            //       changeForm.setFieldsValue({
            //         password: "",
            //         confirmPassword: "",
            //       });
            //       setUserId(record.id);
            //       setIsPasswordModalVisible(true);
            //     }}
            //   >
            //     Change Password
            //   </Button>
            //   |
            //   <Popconfirm
            //     title="Are you sure to delete this user?"
            //     onConfirm={() => {
            //       deleteUser(record.id);
            //     }}
            //     okText="Yes"
            //     cancelText="No"
            //   >
            //     <Button type="link">Delete</Button>
            //   </Popconfirm>
            // </Space>
          );
      },
    },
  ];

  return (
    <Fragment>
      <PageHeader
        title="Clients"
        className="bg-white d-flex justify-content-between align-items-center"
      >
        <Button
          size="large"
          key="1"
          type="primary"
          onClick={() => {
            form.resetFields();
            setIsInviteModalVisible(true);
          }}
        >
          <PlusOutlined /> Invite
        </Button>
      </PageHeader>
      <div className="cercadian-content-area p-3">
        <Card className="h-100 bg-white pt-0">
          <Table
            className="cercadian-table"
            dataSource={userList}
            columns={columns}
          />
        </Card>
      </div>
      <Modal
        title="Invite Client"
        visible={isInviteModalVisible}
        okText="Invite"
        onOk={handleOk}
        okButtonProps={{ loading: isLoading }}
        onCancel={handleCancel}
      >
        <Form
          layout="vertical"
          name="invite-form"
          form={form}
          onFinish={onSubmit}
        >
          <Row gutter={[16, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  { required: true, message: "Please enter first name !" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  { required: true, message: "Please enter last name !" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please enter valid email!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="companyName"
            label="Company"
            rules={[{ required: true, message: "Please enter company name !" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Change Password"
        visible={isPasswordModalVisible}
        okText="Change"
        onOk={() => {
          changeForm.submit();
        }}
        okButtonProps={{ loading: isLoading }}
        onCancel={() => {
          setIsPasswordModalVisible(false);
        }}
      >
        <Form
          name="change-password-form"
          form={changeForm}
          onFinish={changePassword}
        >
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                min: 6,
                message: "Password must be atleast 6 characters long!",
              },
            ]}
          >
            <Input.Password
              prefix={
                <LockOutlined className="site-form-item-icon text-primary" />
              }
              placeholder="Password"
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            rules={[
              () => ({
                validator(rule, value) {
                  return validatePasswordField(
                    value,
                    changeForm.getFieldValue("password")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              prefix={
                <LockOutlined className="site-form-item-icon text-primary" />
              }
              placeholder="Confirm Password"
              size="large"
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Change Company Name"
        visible={isCompanyModalVisible}
        okText="Change"
        onOk={() => {
          companyForm.submit();
        }}
        okButtonProps={{ loading: isLoading }}
        onCancel={() => {
          setIsCompanyModalVisible(false);
        }}
      >
        <Form
          name="change-company-form"
          form={companyForm}
          onFinish={changeCompanyName}
        >
          <Form.Item
            name="companyName"
            rules={[
              {
                required: true,
                message: "Please enter company name",
              },
            ]}
          >
            <Input placeholder="Company Name" size="large" />
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
}
