import NetworkCall from "../../network/networkCall";
import Request from "../../network/request";
import { upsertModels } from "../baseModel/baseActions";
import BaseModel from "../baseModel/baseModel";
import baseReducer from "../baseModel/baseReducer";

export default class Permission extends BaseModel {
  static getAll() {
    return async (dispatch) => {
      const list = await NetworkCall.fetch(Request.getPermissions());
      dispatch(upsertModels(Permission, list));
      return list;
    };
  }
  // Reducer
  static reducer(action, Permission, session) {
    baseReducer(action, Permission, session);
  }
}
Permission.modelName = "Permission";
