import K from "../utilities/constants";
import User from "../models/user/user";

export default class DirectRequest {
  constructor(
    relativeURL,
    method = K.Network.Method.GET,
    body = null,
    defaultHeaderType = K.Network.Header.Type.Json,
    headers = {}
  ) {
    const token = User.getToken();
    const permissions = User.getAllPermissions();

    headers = {
      ...(defaultHeaderType === K.Network.Header.Type.Json
        ? K.Network.Header.Default(token, permissions)
        : K.Network.Header.Authorization(token, permissions)),
      ...headers,
    };
  
    this.url = K.Network.URL.BaseCasAPI + relativeURL;
    this.method = method;
    this.body = body;
    this.headers = headers;
  }

  static getXmlFromFile(file) {
    const formData = new FormData();
    formData.append('file', file);

    let user = User.getUserObjectFromCookies().user;

    let urlWithIds = K.Network.URL.WebCasFile.replace("{clientId}", user.companyId).replace("{userId}", user.id);
    
    return new DirectRequest(
      urlWithIds,
      K.Network.Method.POST,
      formData,
      K.Network.Header.Type.XML,
      { "Content-Type": "multipart/form-data", Accept: "application/xml" },
      false
    );
  }
}
