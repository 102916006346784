const K = {
  NullValue: "Circadian",
  Network: {
    URL: {
      // Production
      // Base: 'http://ninjirosoft.com:8080/',
      // BaseAPI: 'http://ninjirosoft.com:8080/api',
      // TenantURL: (domainPrefix = '') => {
      //     return 'http://' + domainPrefix + '.' + 'ninjirosoft.com:8080/api'
      // },
      // Client: {
      //     BaseHost: 'ninjirosoft.com',
      //     BasePort: '80',
      // },

      // Development
      Base: process.env.REACT_APP_BASE_URL,
      BaseAPI: process.env.REACT_APP_BASE_API_URL,
      BaseCasAPI: process.env.REACT_APP_BASE_CAS_API_URL,
      DomainName: process.env.REACT_APP_CLIENT_DOMAIN_NAME,
      Timeout: process.env.REACT_APP_TIMEOUT,
      TenantURL: (domainPrefix = "") => {
        return (
          "http://" + domainPrefix + process.env.REACT_APP_TENANT_PARTIAL_URL
        );
      },
      Client: {
        BaseHost: process.env.REACT_APP_CLIENT_BASE_HOST,
        BasePort: process.env.REACT_APP_CLIENT_BASE_PORT,
      },

      Protocol: "http",

      // Tenant
      GetTenant: "/tenant/get",

      // Assignment
      LoginUser: "/auth/login",

      // Forget password
      ForgotPassword: "/auth/forgot-password",

      //Reset password
      ResetPassword: "/auth/reset-password",

      //set password
      SetPassword: "/auth/set-password",

      // web case message
      WebCaseMessage: "/webcas/send-message",
      
      WebCasFile: "/FRMS/{clientId}/services/json/file?request=getFatigueReport&userId={userId}",

      SavedReports: "/report",

      Roles: "/role",

      User: "/user",

      ClientUser: "/user/clients",

      UpdateUserRole: "/user/update-role",

      ChangeUserPassword: "/user/change-password",

      Permission: "/permission",

      SendInvite: "/auth/send-invite",

      SendClientInvite: "/auth/send-client-invite",

      UpdateCompany: "/company/update-company",
    },
    Method: {
      GET: "GET",
      POST: "POST",
      DELETE: "DELETE",
      PUT: "PUT",
    },
    Header: {
      ContentType: "Content-Type",
      ApplicationJson: "application/json",
      Default: (token = "", permissions = "") => ({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
        Permissions: permissions,
      }),
      Authorization: (token = "", permissions = "") => ({
        Authorization: "Bearer " + token,
        Permissions: permissions,
      }),
      Type: {
        Json: "json",
        File: "file",
        XML: "xml",
      },
    },
    Default: {
      AssignmentStatusID: 1,
      ResourceAllocationPercentage: 100,
      ResourceAllocationType: "percentage",
      WorkItem: "",
      Error: "Opps, an error occurred!",
    },
    StatusCode: {
      Unauthorized: 401,
    },
  },
  Actions: {
    // General part of action
    CREATE: "CREATE",
    UPSERT: "UPSERT",
    DELETE: "DELETE",
    DELETE_ALL: "DELETE_ALL",
    SET: "SET",

    // Settings
    UPSERT_SETTING: "UPSERT_SETTING",
  },

  Cookie: {
    Key: {
      User: "user",
      EncryptionKey: "circadian_logged_in_user",
    },
  },

  Roles: {
    Admin: "Admin",
    User: "User",
  },
  Permissions: {
    SuperAdmin: "SUPER.ADMIN",
    InviteUser: "USER.INVITE",
    SaveReports: "REPORTS.SAVE",
    ViewReports: "REPORTS.VIEW",
    ViewUserListing: "USER.LISTING",
    ViewOwnReports: "REPORTS.VIEW.OWN",
    ManipulateUser: "USER.MANIPULATE",
  },
};

export default K;
